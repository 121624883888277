import { adminPermission } from '../helpers/permission';

export interface IMenuItem {
  icon?: string;
  icon_type?: 'img' | 'mat';
  title: string;
  route: any | any[];
  child: IChildItem[];
  svgIcon: string;
  permissions?: string[];
}

export interface IChildItem {
  icon?: string;
  icon_type?: 'img' | 'mat';
  title: string;
  route: any | any[];
  svgIcon: string;
  permissions?: string[];
}

export const MENU = [
  {
    title: 'main_menu.dashboard',
    route: '/home',
    icon: '',
    svgIcon: 'dashboard',
    child: [],
    permissions: [adminPermission.reportData.dashboard]
  },
  {
    title: 'request_app_form.list',
    route: '/request',
    icon: '',
    svgIcon: 'request-app-form',
    child: [
      {
        title: 'common.requesting',
        route: ['/request/requesting'],
        icon: '',
        svgIcon: '',
        permissions: [adminPermission.applicationRequest.read]
      },
      {
        title: 'common.all_request',
        route: ['/request/all'],
        icon: '',
        svgIcon: '',
        permissions: [adminPermission.applicationRequest.read]
      }
    ],
    permissions: []
  },
  {
    title: 'main_menu.manage_account',
    route: '/user-account',
    icon: '',
    svgIcon: 'user-account',
    child: [],
    permissions: [adminPermission.userAccount.read]
  },
  {
    title: 'main_menu.report',
    route: '/report',
    icon: '',
    svgIcon: 'report',
    child: [
      {
        title: 'common.request_amount',
        route: ['/report/amount-of-request'],
        icon: '',
        svgIcon: '',
        permissions: [adminPermission.reportData.serviceRequestCount]
      },
      {
        title: 'report.request_by_department',
        route: ['/report/request-by-department'],
        icon: '',
        svgIcon: '',
        permissions: [adminPermission.reportData.departmentRequestCount]
      },
      {
        title: 'report.profit_by_department',
        route: ['/report/payment-by-location'],
        icon: '',
        svgIcon: '',
        permissions: [adminPermission.reportData.paymentByLocation]
      },
      {
        title: 'report.profit_by_sector',
        route: ['/report/payment-by-sector'],
        icon: '',
        svgIcon: '',
        permissions: [adminPermission.reportData.paymentBySector]
      }
    ],
    permissions: []
  },
  {
    title: 'main_menu.setting',
    route: '/setting',
    icon: 'settings',
    svgIcon: '',
    child: [
      {
        title: 'sector.name',
        route: ['/setting/sector'],
        icon: '',
        svgIcon: '',
        permissions: [adminPermission.sector.read]
      },
      {
        title: 'document_form.name',
        route: ['/setting/document-type'],
        icon: '',
        svgIcon: '',
        permissions: [adminPermission.documentForm.read]
      },
      {
        title: 'service.service_type',
        route: ['/setting/application-service'],
        icon: '',
        svgIcon: '',
        permissions: [adminPermission.applicationService.read]
      },
      {
        title: 'service.service_by_level_menu',
        route: ['/setting/admin-service-level'],
        icon: '',
        svgIcon: '',
        permissions: [adminPermission.departmentServiceLevel.read]
      },
      // {
      //   title: 'department.name',
      //   route: ['/setting/department'],
      //   icon: '',
      //   svgIcon: '',
      //   permissions: [adminPermission.department.read]
      // },
      {
        title: 'role.name',
        route: ['/setting/role'],
        icon: '',
        svgIcon: '',
        permissions: [adminPermission.department.read]
      }
    ],
    permissions: []
  }
];
